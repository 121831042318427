import { useStaticQuery, graphql } from 'gatsby';

const V2TopImages = image => {
  const data = useStaticQuery(graphql`
    query {
      FVimgPC: file(relativePath: { eq: "v2/FV_PC@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 833, maxHeight: 470) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FVimgTB: file(relativePath: { eq: "v2/FV_TB@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 520, maxHeight: 358) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FVimgSP: file(relativePath: { eq: "v2/FV_SP@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 383, maxHeight: 264) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DXoutsideImgPC: file(
        relativePath: { eq: "v2/dx-system-development_PC.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 513, maxHeight: 183) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DXoutsideImgSP: file(
        relativePath: { eq: "v2/dx-system-development_SP.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 686, maxHeight: 770) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DXinsideImg: file(relativePath: { eq: "v2/dx-hr-development_SP.png" }) {
        childImageSharp {
          fluid(maxWidth: 208, maxHeight: 183) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DXoutsideIllust: file(relativePath: { eq: "v2/DX-outside@3.png" }) {
        childImageSharp {
          fluid(maxWidth: 208, maxHeight: 343) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DXinsideIllust: file(relativePath: { eq: "v2/DX-inside@3.png" }) {
        childImageSharp {
          fluid(maxWidth: 208, maxHeight: 343) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      StrongPoint01: file(relativePath: { eq: "v2/strong_point01.png" }) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 172) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      StrongPoint02: file(relativePath: { eq: "v2/strong_point02.png" }) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 172) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      StrongPoint03: file(relativePath: { eq: "v2/strong_point03.png" }) {
        childImageSharp {
          fluid(maxWidth: 288, maxHeight: 172) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      FvmBanner: file(relativePath: { eq: "v2/FVM_banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 320, maxHeight: 104) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return data[image].childImageSharp.fluid;
};

export default V2TopImages;
