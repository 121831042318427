import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PageRoot from '../components/common-components/templates/page-root-v2';
import pagesV2 from '../constants-v2/pages-v2';

import X from '../images-v2/svg/x_technorogyxdesign.svg';
import Plus from '../images-v2/svg/plus_DX.svg';
import FlaskLogoSrc from '../images-v2/svg/icon_flask.svg';
import TriangleTop from '../images-v2/svg/triangle_top.svg';
import TriangleBottom from '../images-v2/svg/triangle_bottom.svg';
import '../styles/foundation-v2.css';
import Works from '../components/work-v2/index';
import ContentList from '../components/content-components-v2/search/content_list';
// import RegnioFactoryFvmBanner from '../components/common-components/buttons/regniofactory-banner';

import colorPaletteV2 from '../styles/color-palette-v2';

import V2TopImages from '../images/gatsby-image/v2-top/fluid-images';

const FirstContent = styled.div`
  margin: 20px auto 60px !important;
  margin: 20px auto 60px !important;
  @media (min-width: 960px) {
    margin-top: 180px !important;
    margin-top: 180px !important;
  }
`;

const CatchCopy = styled.div`
  letter-spacing: 0;
  /* margin-top: 70px;
  margin-top: 70px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 959px) {
    display: grid;
    width: 252px;
    margin: 54px auto 60px;
    margin: 54px auto 60px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 959px) {
    .copy1 {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
      padding-bottom: 4px;
      padding-bottom: 4px;
      padding-left: 12px;
      padding-left: 12px;
    }
    .copy2 {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }
  }
`;
const TechnologyxDesign = styled.div`
  font-size: 36px;
  font-size: 36px;
  font-weight: 700;
  text-align: right;
  margin-right: 32px;
  margin-right: 32px;
  @media (max-width: 959px) {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    font-size: 16px;
    font-size: 16px;
    margin-right: 24px;
    margin-right: 24px;
  }
  .technology {
    margin-bottom: 4px;
    margin-bottom: 4px;
    @media (max-width: 959px) {
      margin-top: 20px;
      margin-top: 20px;
    }
  }
`;
const Xmark = styled.img`
  display: inline-block;
  width: 26px;
  width: 26px;
  height: 26px;
  height: 26px;
  margin-right: 8px;
  margin-right: 8px;
  @media (max-width: 959px) {
    width: 10px;
    width: 10px;
    height: 10px;
    height: 10px;
  }
`;
const Copy = styled.div`
  font-weight: 700;
  margin-top: 6px;
  margin-top: 6px;
  .kana {
    font-size: 18px;
    font-size: 18px;
    margin-bottom: 6px;
    margin-bottom: 6px;
  }
  .copy {
    .txtL {
      font-size: 48px;
      font-size: 48px;
    }
    .txtM {
      font-size: 32px;
      font-size: 32px;
      position: relative;
      top: -2px;
      top: -2px;
      margin-left: 2px;
      margin-left: 2px;
      @media (max-width: 959px) {
        top: 0;
      }
    }
  }
  @media (max-width: 959px) {
    .kana {
      font-size: 12px;
      font-size: 12px;
    }
    .copy {
      .txtL {
        font-size: 38px;
        font-size: 38px;
      }
      .txtM {
        font-size: 22px;
        font-size: 22px;
      }
    }
  }
`;
const SubCopy = styled.p`
  font-size: 16px;
  font-size: 16px;
  text-align: center;
  margin: 34px auto 60px;
  margin: 34px auto 60px;
  font-feature-settings: normal;
  @media (max-width: 959px) {
    text-align: left;
    width: 240px;
    width: 240px;
    margin: 40px auto 74px;
    margin: 40px auto 74px;
    font-feature-settings: 'palt';
    line-height: 1.8;
  }
`;
const FVimgPC = styled(Img)`
  width: 833px;
  width: 833px;
  height: 470px;
  height: 470px;
  margin: 0 auto 74px;
  margin: 0 auto 74px;
  @media (max-width: 959px) {
    display: none;
  }
`;
const FVimgSP = styled(Img)`
  width: 100%;
  min-height: 216px; // 仮
  min-height: 216px; // 仮
  margin: 0 auto;
  margin: 0 auto;
  @media (min-width: 520px) {
    display: none;
  }
`;
const FVimgTB = styled(Img)`
  width: 100%;
  margin: 0 auto;
  margin: 0 auto;
  @media (max-width: 519px) {
    display: none;
  }
  @media (min-width: 960px) {
    display: none;
  }
`;

const AboutDX = styled.div`
  @media (min-width: 960px) {
    padding: 0 48px;
    padding: 0 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 52px;
    margin-bottom: 52px;
  }
  .DXdescription {
    .description {
      margin-top: 20px;
      margin-top: 20px;
      margin-bottom: 24px;
      margin-bottom: 24px;
      line-height: 1.5;
      font-size: 16px;
      font-size: 16px;
      @media (max-width: 959px) {
        text-align: center;
      }
      .point {
        font-size: 24px;
        font-size: 24px;
        font-weight: bold;
        @media (max-width: 959px) {
          font-size: 19px;
          font-size: 19px;
        }
      }
    }
  }
`;

const AboutDXTitle = styled.h2`
  font-weight: 700;
  margin-bottom: 18px;
  margin-bottom: 18px;
  @media (max-width: 959px) {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  .txtL {
    font-size: 36px;
    font-size: 36px;
  }
  .txtM {
    font-size: 24px;
    font-size: 24px;
  }
  .txtL2 {
    font-size: 40px;
    font-size: 40px;
  }
`;

const AboutDXsubTitle = styled.div`
  font-weight: 700;
  margin-bottom: 28px;
  margin-bottom: 28px;
  font-size: 22px;
  font-size: 22px;
  @media (max-width: 959px) {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
`;

const PlusMark = styled.img`
  width: 48px;
  width: 48px;
  height: 48px;
  height: 48px;
  margin-top: -94px;
  margin-top: -94px;
  @media (max-width: 959px) {
    margin: 40px auto;
    margin: 40px auto;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
`;

const DXoutsideImgPC = styled(Img)`
  width: 100%;
  margin: 0 auto;
  display: none;
  @media (min-width: 960px) {
    width: 512px;
    width: 512px;
    height: 183px;
    height: 183px;
    display: block;
  }
`;

const DXoutsideImgSP = styled(Img)`
  width: 100%;
  max-width: 315px;
  max-width: 315px;
  margin: 0 auto;
  display: block;
  @media (min-width: 960px) {
    display: none;
  }
`;

const DXinsideImg = styled(Img)`
  width: 100%;
  max-width: 315px;
  max-width: 315px;
  margin: 0 auto;
  @media (min-width: 960px) {
    max-width: 100%;
    width: 208px;
    width: 208px;
    height: 183px;
    height: 183px;
  }
`;

const Grad1Button = styled(Link)``;

const WithTriangle = styled.img`
  width: 100%;
  height: auto;
`;

const WithTriangleBody = styled.section`
  .text_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 16px;
    font-size: 16px;
    line-height: 1.8;
    margin-top: 48px;
    margin-top: 48px;
    @media (min-width: 520px) {
      flex-direction: row;
    }
    .inner_wrap {
      width: 100%;
      padding-bottom: 20px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      margin-bottom: 20px;
      @media (min-width: 520px) {
        width: 30%;
      }
      @media (min-width: 960px) {
        width: 288px;
        width: 288px;
        margin-bottom: 0;
      }
      .inner_txt {
        text-align: center;
        @media (min-width: 960px) {
          text-align: left;
        }
      }
    }
    .inner_wrap:last-child {
      @media (max-width: 519px) {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
  .section_sub_title {
    font-size: 20px;
    font-size: 20px;
  }
`;

const PointImg = styled(Img)`
  width: 100%;
  min-height: 172px; // 仮
  min-height: 172px; // 仮
  @media (min-width: 960px) {
    height: 180px;
    height: 180px;
  }
`;

const PopmtTitle = styled.h3`
  font-size: 20px;
  font-size: 20px;
  font-weight: bold;
  margin-top: 12px;
  margin-top: 12px;
  margin-bottom: 4px;
  margin-bottom: 4px;
  text-align: center;
`;

const Suggestion = styled.section`
  display: grid;
  padding-top: 80px;
  padding-top: 80px;
  padding-bottom: 100px;
  padding-bottom: 100px;
  @media (max-width: 519px) {
    grid-column-gap: 16px;
    grid-column-gap: 16px;
    grid-row-gap: 32px;
    grid-row-gap: 32px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    .illust1 {
      grid-column: 1/2;
      grid-row: 1/2;
    }
    .illust2 {
      grid-column: 2/3;
      grid-row: 1/2;
    }
    .inner_content {
      grid-column: 1/3;
      grid-row: 2/3;
    }
  }
  @media (min-width: 520px) {
    grid-column-gap: 32px;
    grid-column-gap: 32px;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .inner_content {
      grid-column: 2/4;
      grid-row: 1/2;
    }
  }
  .inner_content_title {
    line-height: 1.5;
    .Ssize {
      font-size: 18px;
      font-size: 18px;
      margin-top: 16px;
      margin-top: 16px;
    }
    .Lsize {
      font-size: 23px;
      font-size: 23px;
    }
    @media (min-width: 520px) {
      .Ssize {
        font-size: 20px;
        font-size: 20px;
        display: inline-block;
        margin-top: 0;
      }
      .Lsize {
        font-size: 28px;
        font-size: 28px;
      }
    }
    @media (min-width: 960px) {
      .Ssize {
        font-size: 24px;
        font-size: 24px;
        display: inline-block;
        margin-top: 0;
      }
      .Lsize {
        font-size: 36px;
        font-size: 36px;
      }
    }
  }
  .txt {
    font-size: 16px;
    font-size: 16px;
    line-height: 1.8;
    margin-top: 16px;
    margin-top: 16px;
  }
  a {
    color: ${colorPaletteV2.blue};
    font-weight: 500;
  }
  .contactBtn {
    margin-top: 32px;
    margin-top: 32px;
  }
`;

const SuggestionImg = styled(Img)`
  width: 100%;
  height: auto;
  display: block;
  @media (min-width: 960px) {
    height: 343px;
    height: 343px;
  }
`;
const ButtonL = styled(Link)`
  margin: 0 auto;
`;
const ButtonLStr = styled.span`
  line-height: 1;
`;
const WorksContents = styled.section`
  background-color: ${colorPaletteV2.white};
  width: 100%;
  @media (max-width: 1023px) {
    padding-top: 86px;
    padding-bottom: 80px;
  }
  @media (min-width: 1024px) {
    padding-top: 86px;
    padding-bottom: 100px;
  }
`;

const SectionTitleFlex = styled.div`
  display: flex;
  align-items: baseline;
`;

const SectitonTitleright = styled.div`
  margin-left: 16px;
`;

const YomimonoContents = styled.section`
  @media (min-width: 520px) {
    .section_inner {
      padding: 18px 0 100px;
    }
  }
  @media (min-width: 960px) {
    .section_inner {
      padding: 86px 0 100px;
    }
  }
`;

const ContentListOuter = styled.div`
  margin: 56px 0;
`;

const TextLink = styled(Link)`
  display: inline;
`;

const HomePage = () => {
  return (
    <PageRoot>
      <FirstContent className="wrapper-v2 outer">
        <CatchCopy>
          <TechnologyxDesign>
            <div className="technology">TECHNOLOGY</div>
            <div className="xdesign">
              <Xmark src={X} alt="×" />
              <span className="design">DESIGN</span>
            </div>
          </TechnologyxDesign>
          <Copy className="copy1">
            <div className="kana">株式会社リグニオ</div>
            <div className="copy">
              <span className="txtL">Regnio</span>
              <span className="txtM">の考える</span>
            </div>
          </Copy>
          <Copy className="copy2">
            <div className="kana">事業変革</div>
            <div className="copy">
              <span className="txtL">DX</span>
              <span className="txtM">とは</span>
              <span className="txtL">？</span>
            </div>
          </Copy>
        </CatchCopy>
        <FVimgSP fluid={V2TopImages('FVimgSP')} />
        <FVimgTB fluid={V2TopImages('FVimgTB')} />
        <SubCopy>
          幅広い技術力と深い業務理解を組み合わせ、
          <br className="display_none_PC" />
          外側からも内側からも
          <br className="display_none_PC" />
          お客様のDXを推進します
        </SubCopy>
        <FVimgPC fluid={V2TopImages('FVimgPC')} />

        <AboutDX>
          <div className="DXdescription outside">
            <AboutDXTitle>
              <span className="txtL">外側</span>
              <span className="txtM">からの</span>
              <span className="txtL2">DX</span>
            </AboutDXTitle>
            <AboutDXsubTitle>
              <span className="grad_txt">事業変革プログラム</span>
            </AboutDXsubTitle>
            <DXoutsideImgPC fluid={V2TopImages('DXoutsideImgPC')} />
            <DXoutsideImgSP fluid={V2TopImages('DXoutsideImgSP')} />
            <p className="description">
              現場の視察やヒアリングにより
              <br className="display_only_SP" />
              業務を深く理解し、
              <br />
              <strong className="point">システム開発を通じて</strong>
              お客様の
              <br className="display_only_SP" />
              DX推進を行うプログラム
            </p>
            <Grad1Button
              className="grad1_border_button sizeM"
              to="/dx-system-development"
            >
              <span className="grad1_border_button_inside">詳しく見る</span>
            </Grad1Button>
          </div>
          <PlusMark src={Plus} alt="＋" />
          <div className="DXdescription inside">
            <AboutDXTitle>
              <span className="txtL">内側</span>
              <span className="txtM">からの</span>
              <span className="txtL2">DX</span>
            </AboutDXTitle>
            <AboutDXsubTitle>
              <span className="grad_txt">人材育成プログラム</span>
            </AboutDXsubTitle>
            <DXinsideImg fluid={V2TopImages('DXinsideImg')} />
            <p className="description">
              御社の社員を
              <br />
              <strong className="point">DX技術者へと育成</strong>
            </p>
            <Grad1Button
              className="grad1_border_button"
              href="https://www.regnio.co.jp/dx-hr-development/"
            >
              <span className="grad1_border_button_inside">詳しく見る</span>
            </Grad1Button>
          </div>
        </AboutDX>
      </FirstContent>

      <WithTriangle src={TriangleTop} />
      <WithTriangleBody className="with_triangle">
        <div className="wrapper-v2">
          <h2 className="section_title">
            <span className="grad_txt">Regnioの強み</span>
          </h2>
          <p className="section_sub_title">
            お客様よりご評価いただいているポイント
          </p>
          <div className="text_wrap">
            <div className="inner_wrap">
              <PointImg fluid={V2TopImages('StrongPoint01')} />
              <PopmtTitle>現場力</PopmtTitle>
              <p className="inner_txt">
                現場での作業、ヒアリング、分析をしっかり行い、現場に寄り添った提案と実装を行います。
              </p>
            </div>
            <div className="inner_wrap">
              <PointImg fluid={V2TopImages('StrongPoint02')} />
              <PopmtTitle>技術力</PopmtTitle>
              <p className="inner_txt">
                ハードとソフトの融合や、ネットワークを含めた総合的なシステム開発が可能です。
              </p>
            </div>
            <div className="inner_wrap">
              <PointImg fluid={V2TopImages('StrongPoint03')} />
              <PopmtTitle>伴走力</PopmtTitle>
              <p className="inner_txt">
                単発のシステム開発ではなく、お客様の事業変革を目指した長期で深いお付き合いを行います。
              </p>
            </div>
          </div>
        </div>
      </WithTriangleBody>
      <WithTriangle src={TriangleBottom} />

      <Suggestion className="wrapper-v2">
        <div className="illust1">
          <SuggestionImg fluid={V2TopImages('DXoutsideIllust')} />
        </div>
        <div className="inner_content">
          <h1 className="inner_content_title">
            <span className="Ssize">長期的な目線と戦略で</span>
            <br />
            <span className="Lsize">
              外側と内側の両方から、
              <br />
              DX（事業変革）しませんか。
            </span>
          </h1>
          <p className="txt">
            Regnioは、単発のシステム開発やツールの導入ではなく、お客様と継続的且つ長期的なパートナーシップを築き、
            <TextLink alt={pagesV2.DXoutside.name} to={pagesV2.DXoutside.path}>
              コンサルティング、システム開発、運用サービス
            </TextLink>
            を提供します。
          </p>
          <p className="txt">
            それに加えて
            <TextLink alt={pagesV2.DXinside.name} to={pagesV2.DXinside.path}>
              人材育成
            </TextLink>
            も含めた包括的なDXサービスを提供し、最新技術と深い業務理解が組み合わさった、外側と内側両方からの事業変革サービスを提供します。
          </p>
          <Grad1Button className="grad1_border_button contactBtn" to="/contact">
            <span className="grad1_border_button_inside">
              ご相談・お問い合わせ
            </span>
          </Grad1Button>
        </div>
        <div className="illust2">
          <SuggestionImg fluid={V2TopImages('DXinsideIllust')} />
        </div>
      </Suggestion>
      <WorksContents>
        <div className="section_inner">
          <div className="wrapper-v2">
            <Works />
          </div>
        </div>
      </WorksContents>
      <YomimonoContents>
        <div className="wrapper-v2">
          <div className="section_inner">
            <div>
              <SectionTitleFlex>
                <h2 className="section_title_left">読み物</h2>
                <SectitonTitleright>
                  <SectionTitleFlex>
                    <img className="FlaskLogo" src={FlaskLogoSrc} alt="" />
                    <p className="grad_txt section_title_right">Regnio Lab</p>
                  </SectionTitleFlex>
                </SectitonTitleright>
              </SectionTitleFlex>
              <ContentListOuter>
                <ContentList />
              </ContentListOuter>
              <ButtonL
                className="grad1_border_button sizeL"
                to={pagesV2.contents.path}
              >
                <ButtonLStr className="grad1_border_button_inside">
                  読み物一覧へ
                </ButtonLStr>
              </ButtonL>
            </div>
          </div>
        </div>
      </YomimonoContents>
    </PageRoot>
  );
};

export default HomePage;
